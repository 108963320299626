enum CMMLogLevel {
  EMERGENCY = 0,
  ALERT,
  CRITICAL,
  ERROR,
  WARNING,
  NOTICE,
  INFO,
  DEBUG,
}
interface CMMLog {
  message: string;
  component: string;
  severity: CMMLogLevel;
  program?: string;
}

function cmm_log_data(sendData: CMMLog) {
  var http = new XMLHttpRequest();
  var url = 'https://logs.api.creekmoremarketing.com/logs.php';

  sendData.program = 'HDSites';

  if (sendData.message) {
    sendData.message = sendData.message + ' [' + window.location.href + ']';
  }

  http.open('POST', url, true);

  http.setRequestHeader('Content-type', 'application/json');

  http.onreadystatechange = function () {
    if (http.readyState == 4 && http.status == 200) {
      //data sent successfully
    }
  };
  http.send(JSON.stringify(sendData));
}

type LanguageProperties = {
  'en-US'?: string;
  'en-CA'?: string;
  'fr-CA'?: string;
  fallback?: string;
};
type LanguageAssetProperties = LanguageProperties & {
  placeholder: string | null;
  flexBasis: string;
};
type PromotionAssets = {
  [key: string]: LanguageAssetProperties;
};
type PromotionDetails = {
  label: string | LanguageProperties;
  shortDetails?: string;
  alt: string | LanguageProperties;
  start: Date;
  startShow?: Date;
  end?: Date;
  linkAsset: string;
  defaultImg: string;
  assets: PromotionAssets;
};
type FileFolders = {
  [key: string]: {
    [key: string]: PromotionDetails;
  };
};

if (typeof window.CMMPromotionLoader === 'undefined') {
  var CMMPromotionLoader = class {
    // https://support.cloudinary.com/hc/en-us/articles/202520852-How-can-I-update-an-already-uploaded-image-

    public static FILE_LANGAUGE_FOLDERS: FileFolders = {
      'hunter-douglas': {
        quarterly: {
          label: 'Season of Style Savings Event',
          alt: {
            'en-US':
              'Hunter Douglas Promotions, Rebates and Savings on Custom Window Treatments & Coverings',
            fallback:
              'Hunter Douglas Promotions, Rebates and Savings on Custom Window Treatments & Coverings',
          },
          startShow: new Date('September 1, 2024'),
          start: new Date('September 7, 2024'),
          end: new Date('December 19, 2024'),
          linkAsset: 'static-promo-required.pdf',
          defaultImg: 'static-promo-required.png',
          assets: {
            /* Can we add 'fr-CA' keys to these objects safely? */
            'hd-promo-placeholder-square.jpg': {
              'en-US':
                'v1652209584/Promotions/Hunter%20Douglas/%5BPlaceholder%20Images%5D/hd-promo-placeholder-square_k2jv5x.jpg',
              'en-CA':
                'v1652209584/Promotions/Hunter%20Douglas/%5BPlaceholder%20Images%5D/hd-promo-placeholder-square_k2jv5x.jpg',
              'fr-CA': '',
              placeholder: null,
              flexBasis: '100%',
            },
            'hd-promo-placeholder-wide.jpg': {
              'en-US':
                'v1652209584/Promotions/Hunter%20Douglas/%5BPlaceholder%20Images%5D/hd-promo-placeholder-wide_gnxupx.jpg',
              'en-CA':
                'v1652209584/Promotions/Hunter%20Douglas/%5BPlaceholder%20Images%5D/hd-promo-placeholder-wide_gnxupx.jpg',
              'fr-CA': '',
              placeholder: null,
              flexBasis: '100%',
            },
            'static-promo-required.pdf': {
              // ......
              'en-US':
                'v1705350084/Promotions/Hunter%20Douglas/LIVE/en-US/static-promo-required_kgojtt.pdf', // UPLOADED 08/22/24
              'en-CA':
                'v1705351107/Promotions/Hunter%20Douglas/LIVE/en-CA/static-promo-required_lq6707.pdf', // UPLOADED 08/22/24
              'fr-CA': '', // NONE PROVIDED
              placeholder: null,
              flexBasis: '0',
            },
            'static-promo-required.png': {
              'en-US':
                'v1705350081/Promotions/Hunter%20Douglas/LIVE/en-US/static-promo-required_mgmogr.jpg', // UPLOADED 08/22/24
              'en-CA':
                'v1705351106/Promotions/Hunter%20Douglas/LIVE/en-CA/static-promo-required_zhmyes.jpg', // UPLOADED 08/22/24
              'fr-CA': '', // NONE PROVIDED
              placeholder: 'hd-promo-placeholder-wide.jpg',
              flexBasis: '500px',
            },
            'banner-horizontal.jpg': {
              'en-US':
                'v1705350081/Promotions/Hunter%20Douglas/LIVE/en-US/banner-horizontal_un3baa.jpg', // UPLOADED 08/22/24
              'en-CA':
                'v1705351108/Promotions/Hunter%20Douglas/LIVE/en-CA/banner-horizontal_h0mrop.jpg', // UPLOADED 08/22/24
              'fr-CA': '', // NONE PROVIDED
              placeholder: 'hd-promo-placeholder-wide.jpg',
              flexBasis: '500px',
            },
            'promo-logo.png': {
              'en-US':
                'v1705350851/Promotions/Hunter%20Douglas/LIVE/en-US/promo-logo_wu3qas.jpg', // UPLOADED 08/22/24
              'en-CA':
                'v1705351111/Promotions/Hunter%20Douglas/LIVE/en-CA/promo-logo_fu7ajg.jpg', // UPLOADED 08/22/24
              'fr-CA': '', // NONE PROVIDED
              placeholder: 'hd-promo-placeholder-square.jpg',
              flexBasis: '500px',
            },
            'banner-vertical.png': {
              'en-US':
                'v1705350851/Promotions/Hunter%20Douglas/LIVE/en-US/promo-logo_b9rz59.jpg', // UPLOADED 04/23/24 - MISSING VERTICAL IMAGE
              'en-CA':
                'v1705351111/Promotions/Hunter%20Douglas/LIVE/en-CA/promo-logo_grernl.jpg', // UPLOADED 04/23/24 - MISSING VERTICAL IMAGE
              'fr-CA': '', // NONE PROVIDED
              placeholder: 'hd-promo-placeholder-square.jpg',
              flexBasis: '500px',
            },
            'promo-logo-circle.png': {
              'en-US':
                'v1705350081/Promotions/Hunter%20Douglas/LIVE/en-US/promo-circle_gnxiql.png', // UPLOADED 08/22/24
              'en-CA':
                'v1705351110/Promotions/Hunter%20Douglas/LIVE/en-CA/promo-circle_rd98os.png', // UPLOADED 08/22/24
              'fr-CA': '', // NONE PROVIDED
              placeholder: 'hd-promo-placeholder-square.jpg',
              flexBasis: '500px',
            },
            'promo-logo-square.png': {
              'en-US':
                'v1705350082/Promotions/Hunter%20Douglas/LIVE/en-US/promo-square_ndgemx.png', // UPLOADED 08/22/24
              'en-CA':
                'v1705351097/Promotions/Hunter%20Douglas/LIVE/en-CA/promo-square_revj8y.png', // UPLOADED 08/22/24
              'fr-CA': '', // NONE PROVIDED
              placeholder: 'hd-promo-placeholder-square.jpg',
              flexBasis: '500px',
            },
          },
        },
        'federal-tax-credit': {
          label:
            'Save up to $1,200 with Federal Tax Credit on Energy-Efficient Shades',
          alt: 'Save up to $1,200 with Federal Tax Credit on Energy-Efficient Shades',
          start: new Date('January 10, 2023'),
          end: new Date('December 31, 2033'),
          linkAsset: 'promo.pdf',
          defaultImg: 'static-promo-required.png',
          assets: {
            /* Can we add 'fr-CA' keys to these objects safely? */
            'banner-horizontal.jpg': {
              'en-US':
                'v1673469208/Promotions/Hunter%20Douglas%20Federal%20Tax%20Credit/static-banner.png',
              // 'en-CA': 'v1652209584/Promotions/Hunter%20Douglas/%5BPlaceholder%20Images%5D/hd-promo-placeholder-square_k2jv5x.jpg',
              // 'fr-CA': '', // MISSING 9/2/22
              placeholder: null,
              flexBasis: '500px',
            },
            'static-promo-required.png': {
              'en-US':
                'v1673469208/Promotions/Hunter%20Douglas%20Federal%20Tax%20Credit/static-details.jpg',
              // 'en-CA': 'v1652209584/Promotions/Hunter%20Douglas/%5BPlaceholder%20Images%5D/hd-promo-placeholder-wide_gnxupx.jpg',
              // 'fr-CA': '', // MISSING 9/2/22
              placeholder: null,
              flexBasis: '500px',
            },
            'promo.pdf': {
              // ......
              'en-US':
                'v1673469208/Promotions/Hunter%20Douglas%20Federal%20Tax%20Credit/static-promo.pdf', // UPLOADED 9/2/22
              // 'en-CA': 'v1662132303/Promotions/Hunter%20Douglas/LIVE/en-CA/static-promo-required_kikohc.pdf',  // UPLOADED 9/2/22
              // 'fr-CA': 'v1662132073/Promotions/Hunter%20Douglas/LIVE/fr-CA/static-promo-required_gsfvb6.pdf', // UPLOADED 9/2/22
              placeholder: null,
              flexBasis: '500px',
            },
          },
        },
      },
      'benjamin-moore': {
        '2022-may-color-sample': {
          label: 'May 2022 Color Sample Promotion',
          shortDetails: '', //'50% Off Samples',
          alt: 'May 2022 Color Sample Promotion',
          start: new Date('May 4, 2022'),
          startShow: new Date('May 2, 2022'),
          end: new Date('May 16, 2022'),
          linkAsset: 'promo.pdf',
          defaultImg: 'promo-details.png',
          assets: {
            'promo.pdf': {
              'en-US':
                'v1652209601/Promotions/Benjamin%20Moore/May%202022%20Color%20Sample%20Promotion/promo_iojtwy.pdf',
              placeholder: null,
              flexBasis: '500px',
            },
            'promo-square.jpg': {
              'en-US':
                'v1652209597/Promotions/Benjamin%20Moore/May%202022%20Color%20Sample%20Promotion/promo-square_aqw5oq.jpg',
              placeholder: null,
              flexBasis: '500px',
            },
            'promo-details.png': {
              'en-US':
                'v1652209597/Promotions/Benjamin%20Moore/May%202022%20Color%20Sample%20Promotion/promo-details_xcf6xz.png',
              placeholder: null,
              flexBasis: '500px',
            },
            'promo-banner.jpg': {
              'en-US':
                'v1652209597/Promotions/Benjamin%20Moore/May%202022%20Color%20Sample%20Promotion/promo-banner_jkhi9t.jpg',
              placeholder: null,
              flexBasis: '500px',
            },
          },
        },
        'regular-promotions': {
          label: 'August 2023 Ben Interior Paint Promotion',
          shortDetails: '', //'50% Off Samples',
          alt: 'August 2023 Ben Interior Paint Promotion',
          start: new Date('Aug 30, 2023'),
          startShow: new Date('Aug 30, 2023'),
          end: new Date('Sep 5, 2023'),
          linkAsset: 'promo.pdf',
          defaultImg: 'promo-details.png',
          assets: {
            'placeholder-wide': {
              'en-US':
                'v1681158239/Promotions/Benjamin%20Moore/%5BPlaceholder%20Images%5D/bm-promo-placeholder-wide_fc7ffy.jpg',
              placeholder: null,
              flexBasis: '100%',
            },
            'placeholder-square': {
              'en-US':
                'v1681158241/Promotions/Benjamin%20Moore/%5BPlaceholder%20Images%5D/bm-promo-placeholder-square_chztty.jpg',
              placeholder: null,
              flexBasis: '100%',
            },
            'promo.pdf': {
              'en-US':
                'v1677271501/Promotions/Benjamin%20Moore/2023%20August%20Interior%20Paint/2023-august-details_hjxnww.pdf',
              placeholder: null,
              flexBasis: '500px',
            },
            'promo-details.png': {
              'en-US':
                'v1677271502/Promotions/Benjamin%20Moore/2023%20August%20Interior%20Paint/2023-august-details_doajmd.png',
              placeholder: null,
              flexBasis: '500px',
            },
            'promo-banner.png': {
              'en-US':
                'v1677271501/Promotions/Benjamin%20Moore/2023%20August%20Interior%20Paint/2023-august-banner_bfivod.png',
              placeholder: 'placeholder-wide',
              flexBasis: '100%',
            },
          },
        },
      },
      graber: {
        'consumer-rebates': {
          label: 'January 2023 Graber Consumer Rebates',
          shortDetails: '',
          alt: 'January 2023 Graber Consumer Rebates',
          start: new Date('January 1, 2023'),
          end: new Date('April 1, 2023'),
          linkAsset: 'promo.pdf',
          defaultImg: 'details',
          assets: {
            'promo.pdf': {
              'en-US':
                'v1673984134/Promotions/Graber/Consumer%20Rebates/en-US/graber-consumer-rebate-january-2023-flyer_g5fedb.pdf',
              placeholder: null,
              flexBasis: '500px',
            },
            details: {
              'en-US':
                'v1673984134/Promotions/Graber/Consumer%20Rebates/en-US/graber-consumer-rebate-january-2023-flyer_losmg0.jpg',
              placeholder: null,
              flexBasis: '500px',
            },
            'banner-horizontal-motorization': {
              'en-US':
                'v1673984134/Promotions/Graber/Consumer%20Rebates/en-US/banner-horizontal-consumer-motorization_mwgjjd.png',
              placeholder: null,
              flexBasis: '100%',
            },
            'banner-horizontal-cellular-solar-roller': {
              'en-US':
                'v1673984134/Promotions/Graber/Consumer%20Rebates/en-US/banner-horizontal-consumer-cellular-solar-roller_scmz4w.png',
              placeholder: null,
              flexBasis: '100%',
            },
          },
        },
        'free-cordless-lift': {
          label: 'January 2023 Graber Free Cordless Lift',
          shortDetails: '',
          alt: 'January 2023 Graber Free Cordless Lift',
          start: new Date('January 1, 2023'),
          end: new Date('April 1, 2023'),
          linkAsset: 'promo.pdf',
          defaultImg: 'details',
          assets: {
            'promo.pdf': {
              'en-US':
                'v1673984146/Promotions/Graber/Free%20Cordless%20Lift/en-US/graber-jan-apr-2023-promotion-flyer-us_vvtgo7.pdf',
              placeholder: null,
              flexBasis: '500px',
            },
            details: {
              'en-US':
                'v1673984146/Promotions/Graber/Free%20Cordless%20Lift/en-US/graber-jan-apr-2023-promotion-flyer-us_xq15ej.jpg',
              placeholder: null,
              flexBasis: '500px',
            },
            'banner-horizontal': {
              'en-US':
                'v1673984146/Promotions/Graber/Free%20Cordless%20Lift/en-US/banner-horizontal-free-cordless-lift_qg4f07.png',
              placeholder: null,
              flexBasis: '100%',
            },
          },
        },
      },
    };

    /**
     * Set whether the placeholder content should appear manually (toggle true/false).
     *
     * Will automatically change based on start and expiration times.
     */
    private _IS_HD_PLACEHOLDER = false;
    get IS_HD_PLACEHOLDER() {
      return this._IS_HD_PLACEHOLDER;
    }

    private _PROMO_TEXT = 'View Promotion';
    get PROMO_TEXT() {
      return this._PROMO_TEXT;
    }

    private _CURRENT_LANGUAGE = 'en-US';
    get CURRENT_LANGUAGE() {
      return this._CURRENT_LANGUAGE;
    }

    /**
     *
     */
    constructor() {
      if (this.checkPromotionLoadingDisabled()) {
        return;
      }

      // Change the language for all areas if any script has a different language
      this._CURRENT_LANGUAGE = [
        ...document.querySelectorAll('.cmm-promotion-script'),
      ].reduce((prev, curr) => {
        let lang = curr.getAttribute('data-cmm-language');
        if (lang && lang !== prev) {
          return lang;
        } else {
          return prev;
        }
      }, 'en-US');

      // Setup promotion wrapper sizing
      this.initWrappers();

      // Start promotions
      this.initPromotions();

      // Setup body sizing (topbar promotions)
      this.initTopbarSizing();

      this.initListeners();
    }

    /**
     * Disables promotion loading (e.g. backend pages, etc.)
     */
    private checkPromotionLoadingDisabled() {
      // MicroD framework
      if (location.pathname === '/dragdropexpert.aspx') {
        return true;
      }

      return false;
    }

    /**
     *
     * Custom Attributes:
     *
     * cmm-location (required)
     * cmm-href
     * cmm-target
     * cmm-img
     * cmm-text
     * cmm-button
     * cmm-nolink
     */
    private getAttributeDefault(
      elem: HTMLElement,
      att: string,
      fallback: string,
    ): string {
      var value = fallback;
      if (elem.attributes[att]) {
        value = elem.attributes[att].value;
      } else if (elem.attributes['data-' + att]) {
        value = elem.attributes['data-' + att].value;
      } else if (
        elem.parentElement.classList.contains('cmm-promotion-script-wrapper')
      ) {
        value = this.getAttributeDefault(elem.parentElement, att, fallback);
      }
      return value;
    }

    /**
     *
     */
    public static isPromoCurrent(promoDetails: PromotionDetails) {
      const START_DATE = promoDetails.startShow ?? promoDetails.start;
      const EXPIRATION_DATE = promoDetails.end
        ? new Date(promoDetails.end)
        : new Date();
      EXPIRATION_DATE.setDate(
        promoDetails.end
          ? promoDetails.end.getDate() + 1
          : EXPIRATION_DATE.getDate() + 10,
      ); // Add a day to the expiration date to not end at 12:00 AM on that date

      const todayDate = new Date();

      const isNotCurrent =
        (todayDate < START_DATE &&
          (START_DATE < EXPIRATION_DATE ||
            (START_DATE > EXPIRATION_DATE && todayDate > EXPIRATION_DATE))) ||
        (todayDate > EXPIRATION_DATE && START_DATE < EXPIRATION_DATE);

      return !isNotCurrent;
    }

    /**
     *
     */
    public static getLanguageFileLink = function (
      filename: string,
      promoDetails: PromotionDetails,
      isPlaceholder: boolean,
      language: string,
    ): string {
      let transforms = {
        q: 'auto:good',
      };

      if (!filename.endsWith('.pdf')) {
        transforms['w'] = '1200';
        transforms['f'] = 'auto';
      }

      let featuresPart = Object.entries(transforms)
        .map((f) => `${f[0]}_${f[1]}`)
        .join(',');
      const IMAGE_LINK_PREFIX = `https://res.cloudinary.com/creekmore-marketing/image/upload/${featuresPart}/`;

      let fileData = promoDetails.assets[filename];
      if (!fileData) {
        return '';
      }
      let folderPath = CMMPromotionLoader.getLanguageProperty(
        fileData,
        language,
      );

      if (isPlaceholder) {
        fileData = promoDetails.assets[fileData.placeholder];
        if (!fileData) {
          return '';
        }
        folderPath = CMMPromotionLoader.getLanguageProperty(fileData, language);
      }

      // if the image src can't be found, log the error and return blank
      if (!folderPath) {
        cmm_log_data({
          message:
            'File Error: ' +
            filename +
            ' - ' +
            language +
            (isPlaceholder ? ' - Placeholder' : ''),
          component: 'Promotions',
          severity: CMMLogLevel.ERROR,
        });
        return '';
      }

      return IMAGE_LINK_PREFIX + folderPath;
    };

    private static getLanguageProperty(
      stringData: string | LanguageProperties,
      language: string,
    ): string | undefined {
      if (typeof stringData === 'string') {
        return stringData;
      }

      let languageData = stringData[language];

      // check if a fallback option exists
      if (!languageData) {
        languageData = stringData.fallback;
      }

      return languageData;
    }

    /**
     *
     */
    private generateUniqueCode(): number {
      return Math.floor(Math.random() * 9000) + 1000;
    }

    private mapImgTypeToAssetName(
      elem: HTMLElement,
      brand: string,
      promoDetails: PromotionDetails,
    ) {
      let imgType = this.getAttributeDefault(
        elem,
        'cmm-img',
        promoDetails.defaultImg,
      );

      if (brand === 'hunter-douglas') {
        switch (imgType.toLocaleLowerCase()) {
          case 'static-promo-required.png':
            return 'static-promo-required.png';
          case 'logo':
            return 'promo-logo.png';
          case 'banner':
            return 'banner-horizontal.jpg';
          case 'vertical':
            return 'banner-vertical.png';
          case 'circle':
            return 'promo-logo-circle.png';
          case 'none':
            return null;
          default:
            return imgType;
        }
      } else {
        return imgType;
      }
    }

    /**
     *
     */
    private getImgSrc(
      elem: HTMLElement,
      brand: string,
      promoDetails: PromotionDetails,
      isPlaceholder: boolean,
    ) {
      return CMMPromotionLoader.getLanguageFileLink(
        this.mapImgTypeToAssetName(elem, brand, promoDetails),
        promoDetails,
        isPlaceholder,
        this.CURRENT_LANGUAGE,
      );
    }

    private hideEntirePromotion(elem: HTMLElement) {
      elem.style.display = 'none';
    }

    private initListeners(): void {
      // SquareSpace AJAX loading
      window.addEventListener('mercury:load', () => {
        this.initWrappers();
        this.initPromotions();
      });
    }

    /**
     *
     */
    private initWrappers(): void {
      const promoScriptWrappers = document.querySelectorAll(
        '.cmm-promotion-script-wrapper',
      ) as NodeListOf<HTMLElement>;

      promoScriptWrappers.forEach((currentElem) => {
        currentElem.style.display = 'flex';
        currentElem.style.flexDirection = 'row';
        currentElem.style.gap = '20px';

        const TYPE: 'image' | 'topbar' = this.getAttributeDefault(
          currentElem,
          'cmm-type',
          'image',
        ) as any;

        if (TYPE === 'topbar') {
          currentElem.style.position = 'fixed';
          currentElem.style.width = '100%';
          currentElem.style.top = '0';

          if (!currentElem.style.backgroundColor) {
            currentElem.style.backgroundColor = '#000';
          }
          if (!currentElem.style.color) {
            currentElem.style.color = '#fff';
          }
        } else {
          currentElem.style.flexWrap = 'wrap';
        }

        Array.from(currentElem.children).forEach((child) => {
          // Don't set script element styles
          if (child.tagName.toLowerCase() === 'script') return;

          let childElement = child as HTMLElement;
          childElement.style.display = 'flex';

          // Dynamically set the type of all children
          if (TYPE === 'image') {
            childElement.style.flexDirection = 'column';
            childElement.style.justifyContent = 'center';
          } else if (TYPE === 'topbar') {
            childElement.style.flexDirection = 'row';
            childElement.style.justifyContent = 'center';
            childElement.style.alignItems = 'center';
            childElement.style.textAlign = 'center';
            childElement.style.gap = '5px';
            childElement.style.padding = '12px 8px';
            childElement.style.fontSize = '18px';
            childElement.style.minWidth = '400px';
            childElement.style.textDecoration = 'none';
            childElement.style.color = currentElem.style.color;
          }

          if (TYPE !== 'image') {
            childElement.setAttribute('cmm-type', TYPE);
          }
        });
      });
    }

    private isIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    }

    private injectGlobalStyles() {
      if (document.head.querySelector('#cmm-promotion-global-styles')) {
        return;
      }

      const styleTag = document.createElement('style');

      let styles = '';
      styles +=
        '*#dm *.dmBody div.dmCustomHtml:has(.cmm-promotion-script-wrapper) { height: auto !important; }';

      styleTag.innerHTML = styles;

      document.head.append(styleTag);
    }

    /**
     *
     */
    private initPromotions(): void {
      const promoScripts = document.querySelectorAll(
        '.cmm-promotion-script',
      ) as NodeListOf<HTMLElement>;

      this.injectGlobalStyles();

      promoScripts.forEach((currentScriptElem) => {
        const IS_DIV = currentScriptElem.tagName.toLowerCase() == 'div';

        // Check if promotion already exists and continue if it does
        if (
          (IS_DIV && currentScriptElem.children.length > 0) ||
          (!IS_DIV &&
            currentScriptElem.parentElement.querySelectorAll(
              '.cmm-promotion-link',
            ).length > 0)
        ) {
          return;
        }

        // Check if promotion not displayed on blocked origin or paths
        const blockedNullOrigin =
          this.getAttributeDefault(
            currentScriptElem,
            'cmm-blocked-null-origin',
            'true',
          ).toLocaleLowerCase() === 'true';
        const blockedLoadingPaths = this.getAttributeDefault(
          currentScriptElem,
          'cmm-blocked-paths',
          '',
        )
          .split(',')
          .map((path) => path.trim())
          .filter((path) => path != '');
        if (
          (blockedNullOrigin && !window.location.origin) ||
          blockedLoadingPaths.includes(window.location.pathname)
        ) {
          this.hideEntirePromotion(currentScriptElem);
          return;
        }

        const BRAND: 'hunter-douglas' | 'benjamin-moore' | 'graber' =
          this.getAttributeDefault(
            currentScriptElem,
            'cmm-brand',
            'hunter-douglas',
          ) as any;
        const CAMPAIGN = this.getAttributeDefault(
          currentScriptElem,
          'cmm-campaign',
          'quarterly',
        );

        let promoDetails =
          CMMPromotionLoader.FILE_LANGAUGE_FOLDERS[BRAND][CAMPAIGN];
        if (!promoDetails) return;

        // Check if placeholder data should be used
        // Set the placeholder based on the date start/stop
        const IS_PLACEHOLDER =
          (BRAND === 'hunter-douglas' && this.IS_HD_PLACEHOLDER) ||
          !CMMPromotionLoader.isPromoCurrent(promoDetails);

        const TYPE: 'image' | 'topbar' = this.getAttributeDefault(
          currentScriptElem,
          'cmm-type',
          'image',
        ) as any;

        if (TYPE === 'image') {
          const PROMO_LINK = CMMPromotionLoader.getLanguageFileLink(
            promoDetails.linkAsset,
            promoDetails,
            IS_PLACEHOLDER,
            this.CURRENT_LANGUAGE,
          );

          const flexBasis = this.getAttributeDefault(
            currentScriptElem,
            'cmm-flex-basis-override',
            promoDetails.assets[
              this.mapImgTypeToAssetName(currentScriptElem, BRAND, promoDetails)
            ].flexBasis,
          );
          currentScriptElem.style.flex = `1 0 ${flexBasis}`;

          const linkHref = this.getAttributeDefault(
            currentScriptElem,
            'cmm-href',
            PROMO_LINK,
          );

          let cmmPromoLink = document.createElement('a');
          let doLink =
            this.getAttributeDefault(
              currentScriptElem,
              'cmm-nolink',
              'true',
            ).toLowerCase() === 'true';
          if (doLink) {
            cmmPromoLink.href = linkHref;
          }

          const linkTarget = this.getAttributeDefault(
            currentScriptElem,
            'cmm-target',
            '_blank',
          );

          if (this.isIframe() && doLink) {
            if (linkTarget === '_blank') {
              cmmPromoLink.addEventListener('click', (e) => {
                e.preventDefault();
                window.open(linkHref, '_blank');
              });
            } else {
              cmmPromoLink.target = '_top';
            }
          } else {
            cmmPromoLink.target = linkTarget;
          }

          cmmPromoLink.title = CMMPromotionLoader.getLanguageProperty(
            promoDetails.label,
            this.CURRENT_LANGUAGE,
          );
          cmmPromoLink.style.textDecoration = 'none';
          cmmPromoLink.style.textAlign = 'center';
          cmmPromoLink.style.display = 'block';
          cmmPromoLink.style.boxShadow = 'none';
          cmmPromoLink.classList.add('cmm-promotion-link');

          let uniqueCode = this.generateUniqueCode();
          cmmPromoLink.classList.add('cmm-promotion-link-' + uniqueCode);

          let cmmPromoTextLabel = null;
          let doTextLabel =
            this.getAttributeDefault(
              currentScriptElem,
              'cmm-inline-label',
              'false',
            ) === 'true';
          if (doTextLabel) {
            cmmPromoTextLabel = document.createElement('span');
            cmmPromoTextLabel.textContent = promoDetails.label;

            cmmPromoLink.classList.add('cmm-promo-link-inline');
          }

          const HIDE_PLACEHOLDER =
            this.getAttributeDefault(
              currentScriptElem,
              'cmm-hide-placeholder',
              'false',
            ) === 'true';
          let cmmPromoImg = null;
          let imageSrc = this.getImgSrc(
            currentScriptElem,
            BRAND,
            promoDetails,
            IS_PLACEHOLDER,
          );
          if (!doTextLabel && imageSrc) {
            // Get location
            let cmmLocation = this.getAttributeDefault(
              currentScriptElem,
              'cmm-location',
              '',
            );
            if (cmmLocation != '') cmmLocation = 'Near ' + cmmLocation;

            // Compile alt text
            let altTextArray = [];
            const altTextProperty = CMMPromotionLoader.getLanguageProperty(
              promoDetails.alt,
              this.CURRENT_LANGUAGE,
            );
            if (altTextProperty) altTextArray.push(altTextProperty);
            altTextArray.push(cmmLocation);
            const altText = altTextArray.join(' ');

            cmmPromoImg = document.createElement('img');
            cmmPromoImg.src = imageSrc;
            cmmPromoImg.alt = altText;
            cmmPromoImg.style.display = 'block';
            cmmPromoImg.style.margin = this.getAttributeDefault(
              currentScriptElem,
              'cmm-margin',
              '15px auto 15px',
            );
            cmmPromoImg.style.maxWidth = '100%';
            cmmPromoImg.classList.add('cmm-promotion-img');
            cmmPromoImg.addEventListener('load', function (e: Event) {
              let path = e.composedPath();
              (
                ((path && path[0] ? path[0] : e.target) as HTMLElement)
                  .parentElement.lastElementChild as HTMLElement
              ).style.display = 'none';
            });

            if (IS_PLACEHOLDER && HIDE_PLACEHOLDER) {
              cmmPromoImg.style.display = 'none';
            }
          }

          let cmmPromoText = document.createElement('div');
          cmmPromoText.innerHTML = this.getAttributeDefault(
            currentScriptElem,
            'cmm-text',
            this.PROMO_TEXT,
          );
          cmmPromoText.style.fontSize = '20px';
          cmmPromoText.style.textAlign = 'center';
          cmmPromoText.classList.add('cmm-promotion-text');

          let doButton =
            this.getAttributeDefault(
              currentScriptElem,
              'cmm-hide-button',
              'false',
            ) === 'false';
          if (doButton) {
            let css =
              '.cmm-promotion-link-' +
              uniqueCode +
              ' .cmm-promotion-text {background-color: #f5842a; border: 1px solid #f5842a; color: #fff; transition: background-color 0.2s; border-radius: 38px; font-family: "Open Sans",sans-serif; font-weight: 700; height: auto; padding: 10px 26px; display: inline-block !important;}';
            css +=
              '.cmm-promotion-link-' +
              uniqueCode +
              ' .cmm-promotion-text:hover {background-color: #f9903c; border-color: #f9903c;}';
            css +=
              '.cmm-promotion-link-' +
              uniqueCode +
              '.cmm-promo-link-inline .cmm-promotion-text {margin-left: 0.5em; padding: 0.2em 1em; font-size: 1em !important; line-height: 1.5em;}';
            let style = document.createElement('style');
            style.appendChild(document.createTextNode(css));
            document.head.append(style);
          }

          if (
            IS_PLACEHOLDER &&
            this.getAttributeDefault(
              currentScriptElem,
              'cmm-disable-placeholder-interactions',
              'true',
            ) === 'true'
          ) {
            cmmPromoLink.style.pointerEvents = 'none';
            cmmPromoLink.href = 'javascript:void()';
            cmmPromoText.style.display = 'none';
            cmmPromoText.style.visibility = 'hidden';
            cmmPromoText.style.opacity = '0';
          }

          let wrapChildrenInLink =
            this.getAttributeDefault(
              currentScriptElem,
              'cmm-wrap-children',
              'false',
            ) === 'true';
          if (wrapChildrenInLink && currentScriptElem.childNodes.length > 0) {
            currentScriptElem.childNodes.forEach((node) => {
              cmmPromoLink.appendChild(node);
            });
          }

          if (cmmPromoImg) {
            cmmPromoLink.append(cmmPromoImg);
          }

          if (cmmPromoTextLabel) {
            cmmPromoLink.append(cmmPromoTextLabel);
          }

          cmmPromoLink.append(cmmPromoText);

          if (IS_DIV) {
            currentScriptElem.style.maxWidth = 'min(100%, 1280px)';
            currentScriptElem.style.margin = '0 auto';
            currentScriptElem.appendChild(cmmPromoLink);
          } else {
            currentScriptElem.parentNode.insertBefore(
              cmmPromoLink,
              currentScriptElem,
            );
          }

          // 2024-01-08 - removed "!doButton" check
          if (IS_PLACEHOLDER && HIDE_PLACEHOLDER) {
            this.hideEntirePromotion(currentScriptElem);
          }
        } else if (TYPE === 'topbar') {
          // Text only for promotion details

          if (!IS_DIV) return;

          // Don't show placeholders in topbar
          if (IS_PLACEHOLDER) {
            this.hideEntirePromotion(currentScriptElem);
            return;
          }

          let dateStart = promoDetails.start.toLocaleString('default', {
            month: 'long',
            day: 'numeric',
          });
          let dateEnd =
            promoDetails.start.getMonth() === promoDetails.end.getMonth()
              ? promoDetails.end.getDate()
              : promoDetails.end.toLocaleString('default', {
                  month: 'long',
                  day: 'numeric',
                });
          let dateRange = `${dateStart} - ${dateEnd}`;

          let doLink =
            this.getAttributeDefault(
              currentScriptElem,
              'cmm-nolink',
              'true',
            ).toLowerCase() === 'true';

          let buttonHTML = '';
          if (doLink) {
            buttonHTML = ` <button style="outline: none; background: #fff; color: #000; padding: 3px 8px; border-radius: 3px; border: none; margin-left: 8px; text-transform: uppercase; font-size: 0.8em;">Learn More</button>`;
          }

          currentScriptElem.style.flex = `1 0 650px`;
          currentScriptElem.style.maxWidth = `100%`;

          // &#8226; is bullet character
          let promoHTML = `<strong>${promoDetails.label}</strong><bullet>&#8226;</bullet><span><span style="display: inline-block;">${dateRange}</span>${buttonHTML}</span>`;
          if (promoDetails.shortDetails)
            promoHTML += `<bullet class="cmm-hide-on-mobile">&#8226;</bullet><span class="cmm-hide-on-mobile">${promoDetails.shortDetails}</span>`;
          promoHTML += buttonHTML;

          if (BRAND === 'hunter-douglas') {
            let hdSVG =
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.37 52.36" style="height: 1em; fill: currentColor;"><defs><style>.cls-1{fill:none;}.cls-2{clip-path:url(#clip-path);}.cls-3{fill:transparent;}.cls-4{clip-path:url(#clip-path-2);}.cls-5{clip-path:url(#clip-path-3);}</style><clipPath id="clip-path" transform="translate(-0.96)"><rect class="cls-1" width="55.4" height="52.37"/></clipPath><clipPath id="clip-path-2" transform="translate(-0.96)"><rect class="cls-1" x="27.7" y="26.42" width="27.7" height="25.95"/></clipPath><clipPath id="clip-path-3" transform="translate(-0.96)"><rect class="cls-1" width="27.7" height="26.42"/></clipPath></defs><g id="Layer_2" data-name="Layer 2"><g id="SVGRoot"><g class="cls-2"><g id="g2853"><path id="path2849" class="cls-3" d="M53.34,26.19A26.19,26.19,0,1,1,27.15,0,26.18,26.18,0,0,1,53.34,26.19" transform="translate(-0.96)"/><path id="path2851" d="M53.34,26.19A26.2,26.2,0,0,0,27.15,0V26.19H1A26.17,26.17,0,0,0,27.15,52.36V26.19Z" transform="translate(-0.96)"/></g></g><g class="cls-4"><g id="g2857"><path id="path2855" d="M28.66,52.32A26.18,26.18,0,0,0,53.29,27.69c-13.4,2.49-22.15,11.24-24.63,24.63" transform="translate(-0.96)"/></g></g><g class="cls-5"><g id="g2861"><path id="path2859" d="M25.65.05A26.19,26.19,0,0,0,1,24.68C14.41,22.19,23.17,13.45,25.65.05" transform="translate(-0.96)"/></g></g></g></g></svg>';
            promoHTML = hdSVG + promoHTML;

            currentScriptElem.style.backgroundColor = '#f18300';
          } else if (BRAND === 'benjamin-moore') {
            let bmSVG =
              '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 105.9 86.4" style="height: 1em; fill: currentColor;"><defs><style>.cls-1{fill:inherit;}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="layer"><path class="cls-1" d="M53,0,0,86.4H105.9ZM69.6,78.3H36.5a.78.78,0,0,1-.8-.8,2.27,2.27,0,0,1,.1-.4l9.9-16.2,5.5,8.9-2.6,4.1h0a.6.6,0,0,0-.1.4.79.79,0,0,0,.8.8h7.4a.79.79,0,0,0,.8-.8.6.6,0,0,0-.1-.4h0L44.1,52.2l-.1-.1a.76.76,0,0,0-.5-.2.79.79,0,0,0-.7.4L27,78.3H16.1L42.9,34.8h0a.84.84,0,0,1,.7-.4.79.79,0,0,1,.7.4L70.4,77.2h0c.1.1.1.2.1.4a1,1,0,0,1-.9.7M63.2,52.4a.84.84,0,0,0-.7-.4.79.79,0,0,0-.7.4l-1.5,2.5L54.8,46l7-11.4a.79.79,0,0,1,.7-.4,1,1,0,0,1,.7.4L89.8,78.1H79.1Z"/></g></g></svg>';
            promoHTML = bmSVG + promoHTML;

            currentScriptElem.style.backgroundColor = '#ce1942';
          }

          currentScriptElem.innerHTML = promoHTML;

          if (doLink) {
            let promoLink = CMMPromotionLoader.getLanguageFileLink(
              promoDetails.linkAsset,
              promoDetails,
              IS_PLACEHOLDER,
              this.CURRENT_LANGUAGE,
            );
            promoLink = this.getAttributeDefault(
              currentScriptElem,
              'cmm-href',
              promoLink,
            );

            let replaceHTML = currentScriptElem.outerHTML.replace(
              /^<div/,
              `<a href="${promoLink}"`,
            );
            replaceHTML = replaceHTML.replace(/<\/div>$/, '</a>');

            currentScriptElem.outerHTML = replaceHTML;
          }
        }
      });
    }

    initTopbarSizing(): void {
      let topbarSelector =
        '.cmm-promotion-script-wrapper[cmm-type="topbar"], .cmm-promotion-script-wrapper[data-cmm-type="topbar"]';
      let promoTopbar = document.querySelector(topbarSelector) as HTMLElement;
      if (!promoTopbar) return;

      if (promoTopbar.classList.contains('cmm-promotion-topbar-finished'))
        return;
      promoTopbar.classList.add('cmm-promotion-topbar-finished');

      document.body.style.transition +=
        (document.body.style.transition ? ', ' : '') + 'margin-top 0.2s';
      document.body.style.marginTop = '0';
      document.body.offsetHeight; // trigger reflow
      document.body.style.marginTop = promoTopbar.offsetHeight + 'px';

      let style = '';
      style +=
        '.cmm-promotion-script-wrapper[cmm-type="topbar"] span button, .cmm-promotion-script-wrapper[data-cmm-type="topbar"] span button { display: none; }';
      style += '@media only screen and (max-width: 800px) {';
      style +=
        '.cmm-promotion-script-wrapper[cmm-type="topbar"] bullet, .cmm-promotion-script-wrapper[data-cmm-type="topbar"] bullet { display: none; }';
      style +=
        '.cmm-promotion-script-wrapper[cmm-type="topbar"] > a, .cmm-promotion-script-wrapper[data-cmm-type="topbar"] > a { flex-direction: column !important; gap: 0 !important; position: relative; }';
      style +=
        '.cmm-promotion-script-wrapper[cmm-type="topbar"] svg, .cmm-promotion-script-wrapper[data-cmm-type="topbar"] svg { position: absolute; left: 15px; height: 1.5em !important; }';
      style +=
        '.cmm-promotion-script-wrapper[cmm-type="topbar"] .cmm-hide-on-mobile, .cmm-promotion-script-wrapper[data-cmm-type="topbar"] .cmm-hide-on-mobile { display: none; }';
      style +=
        '.cmm-promotion-script-wrapper[cmm-type="topbar"] span button, .cmm-promotion-script-wrapper[data-cmm-type="topbar"] span button { display: inline-block; }';
      style +=
        '.cmm-promotion-script-wrapper[cmm-type="topbar"] > a > button, .cmm-promotion-script-wrapper[data-cmm-type="topbar"] > a > button { display: none; }';
      style += '}';

      let styleElem = document.createElement('style');
      styleElem.innerHTML = style;
      document.head.append(styleElem);

      // Duda specific
      let hamburgerMenu = document.querySelector(
        '.runtime-module-container .hamburger-header-container',
      ) as HTMLElement;
      if (hamburgerMenu) {
        hamburgerMenu.style.top = promoTopbar.offsetHeight + 5 + 'px';

        let hamburgerMenuIcon = document.querySelector(
          '.responsiveTablet [dmtemplateid=mobileHamburgerLayout].runtime-module-container .layout-drawer-hamburger',
        ) as HTMLElement;
        if (hamburgerMenuIcon) {
          hamburgerMenuIcon.style.cssText +=
            (hamburgerMenuIcon.style.cssText ? ' ' : '') +
            `top: ${promoTopbar.offsetHeight + 17.5 + 5}px !important;`;
          hamburgerMenuIcon.style.zIndex = '6';
        }
      }
    }
  };
}

/**
 * Initialize promotions when ready
 */
if (document.readyState == 'interactive' || document.readyState == 'complete') {
  let cmmPromotion = new CMMPromotionLoader();
} else {
  document.addEventListener('DOMContentLoaded', () => {
    let cmmPromotion = new CMMPromotionLoader();
  });
}
